// Import Event Feed components
const List = () => import(
	/* webpackChunkName: "list" */
	'./components/List.vue'
);
const Widget = () => import(
	/* webpackChunkName: "widget" */
	'./components/Widget.vue'
);


// Load Vue module for every feed on the page
const feeds = document.getElementsByClassName('event-feed-for-eventbrite-app');
[].forEach.call( feeds, function ( element ) {

	// Get data from wp_localize_script by feed ID
	Vue.prototype.scriptName = Function('return EventFeedForEventbrite' + element.dataset.uid)();

	// Set default layout
	Vue.prototype.layoutType = List;

	// Get layout type from feed settings
	if( Vue.prototype.scriptName.feed.layout == 'list' ) { 
		Vue.prototype.layoutType = List
	}
	if( Vue.prototype.scriptName.feed.layout == 'widget' ) {
		Vue.prototype.layoutType = Widget
	}
	

	// Vue instance
	new Vue( {
        el: '#' + element.id,
		components: {
			'layout': Vue.prototype.layoutType,
		},
		data: function() {
            return {

				// Get user data from WP admin
				initialUid: this.scriptName.uid, 
                initialFeed: this.scriptName.feed,
                initialSettings: this.scriptName.settings,
                initialApi: this.scriptName.api,

			}
		}
 	})
	 
}); 